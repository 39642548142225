import { render, staticRenderFns } from "./LanguagesList.vue?vue&type=template&id=281db162&scoped=true&"
import script from "./LanguagesList.vue?vue&type=script&lang=js&"
export * from "./LanguagesList.vue?vue&type=script&lang=js&"
import style0 from "./LanguagesList.vue?vue&type=style&index=0&id=281db162&lang=scss&scoped=true&"
import style1 from "./LanguagesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281db162",
  null
  
)

export default component.exports