<template>
  <b-sidebar
    id="add-new-language-sidebar"
    :visible="isAddNewLanguageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-language-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ button_text }} New Language</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Language Name -->
            <validation-provider
              #default="validationContext"
              name="Language Name"
              rules="required"
            >
              <b-form-group label="Language Name" label-for="language_name">
                <b-form-input
                  id="language_name"
                  v-model="languageData.language_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Language Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Remark -->
            <b-form-group label="Remark" label-for="language_remark">
              <b-form-input
                id="language_remark"
                v-model="languageData.language_remark"
                autofocus
                trim
                placeholder="Remark"
              />
            </b-form-group>
            <!-- Party A Title -->
            <b-form-group label="Party A Title" label-for="party_a_title">
              <b-form-input
                id="party_a_title"
                v-model="languageData.party_a_title"
                autofocus
                trim
                placeholder="Party A Title"
              />
            </b-form-group>
            <!-- Party A Address Title -->
            <b-form-group
              label="Party A Address Title"
              label-for="party_a_address_title"
            >
              <b-form-input
                id="party_a_address_title"
                v-model="languageData.party_a_address_title"
                autofocus
                trim
                placeholder="Party A Address Title"
              />
            </b-form-group>
            <!-- Party B Title -->
            <b-form-group label="Party B Title" label-for="party_b_title">
              <b-form-input
                id="party_b_title"
                v-model="languageData.party_b_title"
                autofocus
                trim
                placeholder="Party B Title"
              />
            </b-form-group>
            <!-- Party B  -->
            <b-form-group label="Party B " label-for="party_b">
              <b-form-input
                id="party_b"
                v-model="languageData.party_b"
                autofocus
                trim
                placeholder="Party B 公司名稱 "
              />
            </b-form-group>
            <!-- Party B Address Title -->
            <b-form-group
              label="Party B Address Title"
              label-for="party_b_address_title"
            >
              <b-form-input
                id="party_b_address_title"
                v-model="languageData.party_b_address_title"
                autofocus
                trim
                placeholder="Party B Address Title"
              />
            </b-form-group>
            <!-- Party B Address -->
            <b-form-group label="Party B Address" label-for="party_b_address">
              <b-form-input
                id="party_b_address"
                v-model="languageData.party_b_address"
                autofocus
                trim
                placeholder="Party B Address"
              />
            </b-form-group>
            <!-- BR NO Title -->
            <b-form-group label="BR NO Title" label-for="br_no_text">
              <b-form-input
                id="br_no_text"
                v-model="languageData.br_no_text"
                autofocus
                trim
                placeholder="BR NO Title"
              />
            </b-form-group>
            <!-- BR NO -->
            <b-form-group label="BR NO" label-for="br_no">
              <b-form-input
                id="br_no"
                v-model="languageData.br_no"
                autofocus
                trim
                placeholder="BR NO"
              />
            </b-form-group>
            <!-- Contract Description -->
            <b-form-group
              label="Contract Description"
              label-for="contract_description"
            >
              <b-form-textarea
                id="contract_description"
                v-model="languageData.contract_description"
                autofocus
                trim
              />
            </b-form-group>
            <!-- Agreement Description -->
            <b-form-group
              label="Agreement Description"
              label-for="agreement_description"
            >
              <b-form-textarea
                id="agreement_description"
                v-model="languageData.agreement_description"
                autofocus
                trim
              />
            </b-form-group>
            <!-- Signature -->
            <b-form-group label="Signature" label-for="signature_title">
              <b-form-input
                id="signature_title"
                v-model="languageData.signature_title"
                autofocus
                trim
                placeholder="Signature title e.g. 授權人簽署及公司蓋章:"
              />
            </b-form-group>
            <!-- Name -->
            <b-form-group label="Name" label-for="signature_name_title">
              <b-form-input
                id="signature_name_title"
                v-model="languageData.signature_name_title"
                autofocus
                trim
                placeholder="Name"
              />
            </b-form-group>
            <!-- Position -->
            <b-form-group label="Position" label-for="signature_title_title">
              <b-form-input
                id="signature_title_title"
                v-model="languageData.signature_title_title"
                autofocus
                trim
                placeholder="Position"
              />
            </b-form-group>
            <!-- Contact Number -->
            <b-form-group label="Contact Number" label-for="signature_contact_number_title">
              <b-form-input
                id="signature_contact_number_title"
                v-model="languageData.signature_contact_number_title"
                autofocus
                trim
                placeholder="Contact Number"
              />
            </b-form-group>
            <!-- Address Title -->
            <b-form-group label="Address Title" label-for="address_title">
              <b-form-input
                id="address_title"
                v-model="languageData.address_title"
                autofocus
                trim
                placeholder="Address Title"
              />
            </b-form-group>
            <!-- Address 1 -->
            <b-form-group label="Address 1" label-for="address1">
              <b-form-input
                id="address1"
                v-model="languageData.address1"
                autofocus
                trim
                placeholder="Address 1"
              />
            </b-form-group>
            <!-- Address 2 -->
            <b-form-group label="Address 2" label-for="address2">
              <b-form-input
                id="address2"
                v-model="languageData.address2"
                autofocus
                trim
                placeholder="Address 2"
              />
            </b-form-group>
            <!-- Address 3 -->
            <b-form-group label="Address 3" label-for="address3">
              <b-form-input
                id="address3"
                v-model="languageData.address3"
                autofocus
                trim
                placeholder="Address 3"
              />
            </b-form-group>
            <!-- Contract Email -->
            <b-form-group label="Contract Email" label-for="contract_email">
              <b-form-input
                id="contract_email"
                v-model="languageData.contract_email"
                autofocus
                trim
                placeholder="Contract Email"
              />
            </b-form-group>
            <!-- Contract Website -->
            <b-form-group label="Contract Website" label-for="contract_website">
              <b-form-input
                id="contract_website"
                v-model="languageData.contract_website"
                autofocus
                trim
                placeholder="Contract Website"
              />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewLanguageSidebarActive",
    event: "update:is-add-new-language-sidebar-active",
  },
  props: {
    isAddNewLanguageSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankLanguageData = {
      id: 0,
      language_name: "",
      language_remark: "",
      party_a_title: "",
      party_a_address_title: "",
      party_b_title: "",
      party_b_address_title: "",
      party_b_address: "",
      br_no_text: "",
      br_no: "",
      contract_description: "",
      address_title: "",
      address1: "",
      address2: "",
      address3: "",
      contract_email: "",
      contract_website: "",
      party_b: "",
      agreement_description: "",
      signature_title: "",
      signature_name_title: "",
      signature_title_title: "",
      signature_contact_number_title: "",
    };
    const button_text = ref("Add");

    const languageData = ref(JSON.parse(JSON.stringify(blankLanguageData)));

    const resetlanguageData = () => {
      languageData.value = JSON.parse(JSON.stringify(blankLanguageData));
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewLanguageSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewLanguageSidebarActive) {
          languageData.value.id = props.data.id;
          languageData.value.language_name = props.data.language_name;
          languageData.value.language_remark = props.data.language_remark;
          languageData.value.party_a_title = props.data.party_a_title;
          languageData.value.party_a_address_title =
            props.data.party_a_address_title;
          languageData.value.party_b_title = props.data.party_b_title;
          languageData.value.party_b_address_title =
            props.data.party_b_address_title;
          languageData.value.party_b_address = props.data.party_b_address;
          languageData.value.br_no_text = props.data.br_no_text;
          languageData.value.br_no = props.data.br_no;
          languageData.value.contract_description =
            props.data.contract_description;
          languageData.value.address_title = props.data.address_title;
          languageData.value.address1 = props.data.address1;
          languageData.value.address2 = props.data.address2;
          languageData.value.address3 = props.data.address3;
          languageData.value.contract_email = props.data.contract_email;
          languageData.value.contract_website = props.data.contract_website;
          languageData.value.party_b = props.data.party_b;
          languageData.value.agreement_description = props.data.agreement_description;
          languageData.value.signature_title = props.data.signature_title;
          languageData.value.signature_name_title = props.data.signature_name_title;
          languageData.value.signature_title_title = props.data.signature_title_title;
          languageData.value.signature_contact_number_title = props.data.signature_contact_number_title;
          if (languageData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }
        } else {
          button_text.value = "Add";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch("setting-contract/addLanguage", languageData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-language-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetlanguageData);

    return {
      languageData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-language-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>